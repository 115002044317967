import React from 'react';

import { connect } from 'react-redux';
import { activateVisualizerDesign, activateVisualizerCode } from '../../redux/visualizer/visualizer.actions';

const Viewports = ( { clientKey, clientName, livesiteUrl, mockImages, featuredCode, designActive, activateVisualizerDesign, activateVisualizerCode } ) => {
    // Variables
    const viewStylesDesktop = { backgroundImage: "url('" + mockImages['desktop'] + "')" };
    const viewStylesTablet = { backgroundImage: "url('" + mockImages['tablet'] + "')" };
    const viewStylesMobile = { backgroundImage: "url('" + mockImages['mobile'] + "')" };

    // Handlers
    const handleViewportClick = ( viewportSize='' ) => {
        if( designActive ) {
            activateVisualizerDesign( clientKey, viewportSize );
        }
        else if( featuredCode ) {
            activateVisualizerCode( clientKey );
        }
    }

    return(
        <div className={ "viewports viewports-" + clientKey + ( featuredCode ? '' : ' no-code' ) }>
            <div className="client-info">
                <div className="name h3">{ clientName }</div>
                { livesiteUrl ?
                    <div className="url">{ livesiteUrl }</div>
                : <div className="url">No Longer Live</div> }
            </div>

            { featuredCode ?
                <div className="overlay-featured-code" onClick={ () => handleViewportClick() } >{ featuredCode }</div>
            : <div className="overlay-featured-code">No Code Available</div> }

            {  mockImages['desktop'] ?
                <div className="viewport viewport-desktop">
                    <div className="view-design" style={ viewStylesDesktop } ></div>
                    <div className="view-code"></div>
                    <div className="overlay-design" onClick={ () => handleViewportClick( 'desktop' ) } ><i className="fas fa-search-plus"></i></div>
                </div>
            : null }

            { mockImages['tablet'] ?
                <div className="viewport viewport-tablet">
                    <div className="view-design" style={ viewStylesTablet } ></div>
                    <div className="view-code"></div>
                    <div className="overlay-design" onClick={ () => handleViewportClick( 'tablet' ) } ><i className="fas fa-search-plus"></i></div>
                </div>
            : null }

            { mockImages['mobile'] ?
                <div className="viewport viewport-mobile">
                    <div className="view-design" style={ viewStylesMobile } ></div>
                    <div className="view-code"></div>
                    <div className="overlay-design" onClick={ () => handleViewportClick( 'mobile' ) } ><i className="fas fa-search-plus"></i></div>
                </div>
            : null }
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    activateVisualizerDesign: ( key, size ) => dispatch( activateVisualizerDesign( { key, size } ) ),
    activateVisualizerCode: key => dispatch( activateVisualizerCode( key ) )
});

export default connect( null, mapDispatchToProps )( Viewports );