import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { selectClientList } from '../../redux/clients/clients.selectors';

import Viewports from '../../components/viewports/viewports.component';

const Portfolio = ( { clientList } ) => {
    return(
        <div className="page-content page-portfolio" >
            <div className='viewports-wrapper'>
                { Object.keys(clientList).map( clientKey => (
                    <Viewports
                        key={'client-viewports-'+clientKey}
                        clientKey={ clientKey }
                        clientName={ clientList[clientKey]['clientName'] }
                        livesiteUrl={ clientList[clientKey]['livesiteUrl'] }
                        mockImages={ clientList[clientKey]['mockImages'] }
                        featuredCode={ clientList[clientKey]['featuredCode'] }
                        designActive={ true }
                    />
                ) ) }
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    clientList: selectClientList
});

export default connect( mapStateToProps )( Portfolio );