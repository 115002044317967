import { VisualizerTypes } from "./visualizer.types";

// Visualizer - Design
export const activateVisualizerDesign = ( { key, size } ) => {
    return {
        type: VisualizerTypes.ACTIVATE_VISUALIZER_DESIGN,
        payload: { key, size }
    }
}

export const deactivateVisualizerDesign = () => {
    return {
        type: VisualizerTypes.DEACTIVATE_VISUALIZER_DESIGN
    }
}

export const setDesignSize = size => {
    return {
        type: VisualizerTypes.SET_DESIGN_SIZE,
        payload: size
    }
}

// Visualizer - Code
export const activateVisualizerCode = key => {
    return {
        type: VisualizerTypes.ACTIVATE_VISUALIZER_CODE,
        payload: key
    }
}

export const deactivateVisualizerCode = () => {
    return {
        type: VisualizerTypes.DEACTIVATE_VISUALIZER_CODE
    }
}

export const setCodeLanguage = lang => {
    return {
        type: VisualizerTypes.SET_CODE_LANGUAGE,
        payload: lang
    }
}