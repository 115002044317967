import { combineReducers } from 'redux';

// Insert reducers here
import clientsReducer from './clients/clients.reducer';
import formsReducer from './forms/forms.reducer';
import shuttersReducer from './shutters/shutters.reducer';
import visualizerReducer from './visualizer/visualizer.reducer';

export default combineReducers({
    clients: clientsReducer,
    forms: formsReducer,
    shutters: shuttersReducer,
    visualizer: visualizerReducer,
})