import { VisualizerTypes } from "./visualizer.types";

const INITIAL_STATE = {
    visualizerClientKey: '',
    visualizerDesignActive: false,
    visualizerDesignSize: '',
    visualizerCodeActive: false,
    visualizerCodeLanguage: 'php',
}

const visualizerReducer = ( state=INITIAL_STATE, action ) => {
    switch( action.type ) {
        // Design
        case VisualizerTypes.ACTIVATE_VISUALIZER_DESIGN:
            return {
                ...state,
                visualizerDesignActive: true,
                visualizerClientKey: action.payload.key,
                visualizerDesignSize: action.payload.size,
            }
        case VisualizerTypes.DEACTIVATE_VISUALIZER_DESIGN:
            return {
                ...state,
                visualizerDesignActive: false,
                visualizerClientKey: '',
                visualizerDesignSize: '',
            }
        case VisualizerTypes.SET_DESIGN_SIZE:
            return {
                ...state,
                visualizerDesignSize: action.payload
            }
        // Code
        case VisualizerTypes.ACTIVATE_VISUALIZER_CODE:
            return {
                ...state,
                visualizerCodeActive: true,
                visualizerClientKey: action.payload,
            }
        case VisualizerTypes.DEACTIVATE_VISUALIZER_CODE:
            return {
                ...state,
                visualizerCodeActive: false,
                visualizerClientKey: '',
                visualizerCodeLanguage: 'php'
            }
        case VisualizerTypes.SET_CODE_LANGUAGE:
            return {
                ...state,
                visualizerCodeLanguage: action.payload
            }
        default:
            return state;
    }
}

export default visualizerReducer;