import React from 'react';

import { connect } from 'react-redux';
import { deactivateShutters } from '../../redux/shutters/shutters.actions';

const HeaderMain = ( { deactivateShutters } ) => {
    return(
        <div className="header-main">
            <div className="header-main-container container">
                <div className="navigation">
                    <div className="nav-item h3 hover-underline" onClick={ deactivateShutters } >Home</div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    deactivateShutters: () => dispatch( deactivateShutters() )
})

export default connect( null, mapDispatchToProps )( HeaderMain );