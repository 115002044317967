import { FormActionTypes } from "./forms.types";

const INITIAL_STATE = {
    formStatus: 'loaded',
    error: null,
}

const formsReducer = ( state=INITIAL_STATE, action ) => {
    switch( action.type ) {
        case FormActionTypes.SET_FORM_SENDING:
            return {
                ...state,
                formStatus: 'sending',
            }
        case FormActionTypes.SEND_FORM_SUCCESS:
            return {
                ...state,
                formStatus: 'success',
                error: null,
            }
        case FormActionTypes.SEND_FORM_FAILURE:
            return {
                ...state,
                formStatus: 'failure',
                error: action.payload,
            }
        default:
            return state;
    }
}

export default formsReducer;