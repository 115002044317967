import { ShuttersActionTypes } from "./shutters.types";

export const setCurrentActiveShutter = clickedShutter => {
    return {
        type: ShuttersActionTypes.SET_CURRENT_ACTIVE_SHUTTER,
        payload: clickedShutter
    }
}

export const deactivateShutters = () => {
    return {
        type: ShuttersActionTypes.DEACTIVATE_SHUTTERS,
    }
}