// Will render in order listed below
export const ClientList = {
    dreamstyle: {
        clientName: "Dreamstyle Remodeling",
        livesiteUrl: "dreamstyleremodeling.com",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/dreamstyle-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/dreamstyle-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/dreamstyle-desktop.jpg",
        },
        featuredCode: "",
    },
    westshore: {
        clientName: "West Shore Home",
        livesiteUrl: "westshorehome.com",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/westshore-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/westshore-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/westshore-desktop.jpg",
        },
        featuredCode: "",
    },
    express: {
        clientName: "Express Sunrooms",
        livesiteUrl: "expresssunrooms.com",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/express-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/express-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/express-desktop.jpg",
        },
        featuredCode: "",
    },
    owens: {
        clientName: "Owen's Corning",
        livesiteUrl: "",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/owens-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/owens-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/owens-desktop.jpg",
        },
        featuredCode: "",
    },
    contractingpro: {
        clientName: "Contracting PRO",
        livesiteUrl: "mycontractingpro.com",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/contractingpro-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/contractingpro-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/contractingpro-desktop.jpg",
        },
        featuredCode: "",
    },
    sinai: {
        clientName: "Sinai Urgent Care",
        livesiteUrl: "sinaiurgentcare.com",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/sinai-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/sinai-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/sinai-desktop.jpg",
        },
        featuredCode: "",
    },
    smartwindow: {
        clientName: "Smart Window Company",
        livesiteUrl: "smartwindowcompany.com",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/smartwindow-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/smartwindow-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/smartwindow-desktop.jpg",
        },
        featuredCode: "",
    },
    paradise: {
        clientName: "Paradise Exteriors",
        livesiteUrl: "",
        mockImages: {
            mobile: "",
            tablet: "",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/paradise-desktop.jpg",
        },
        featuredCode: "",
    },
    sirhome: {
        clientName: "SIR Home Improvements",
        livesiteUrl: "sirhome.com",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/sirhome-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/sirhome-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/sirhome-desktop.jpg",
        },
        featuredCode: "",
    },
    frey: {
        clientName: "Frey Construction",
        livesiteUrl: "freyconstruction.com",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/frey-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/frey-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/frey-desktop.jpg",
        },
        featuredCode: "",
    },
    arrys: {
        clientName: "Arry's Roofing",
        livesiteUrl: "arrysroofing.com",
        mockImages: {
            mobile: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/arrys-mobile.jpg",
            tablet: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/arrys-tablet.jpg",
            desktop: "//apidata.jdlarrea.com/assets/imgs/portfolio-views/arrys-desktop.jpg",
        },
        featuredCode: "",
    },
}