import { ShuttersActionTypes } from "./shutters.types";

const INITIAL_STATE = {
    currentActiveShutter: '',
}

const shuttersReducer = ( state=INITIAL_STATE, action ) => {
    switch( action.type ) {
        case ShuttersActionTypes.SET_CURRENT_ACTIVE_SHUTTER:
            return {
                ...state,
                currentActiveShutter: action.payload
            }
        case ShuttersActionTypes.DEACTIVATE_SHUTTERS:
            return {
                ...state,
                currentActiveShutter: ''
            }
        default:
            return state;
    }
}

export default shuttersReducer;