import React from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectFormStatus } from '../../redux/forms/forms.selectors';
import { sendFormStart } from '../../redux/forms/forms.actions';

import { IMaskInput } from 'react-imask';

class Contact extends React.Component {
    state = {
        Fields: {
            Field1: { value: '', notValidated: true, vMinLength: 2 },
            Field2: { value: '', notValidated: true, vMinLength: 2 },
            Field3: { value: '', notValidated: true, vEmail: true },
            Field4: { value: '', notValidated: true, vMinLength: 11 },
            Field5: { value: '', notValidated: true, vMinLength: 10 },
        }
    }

    handleSubmit = async ( event ) => {
        event.preventDefault();

        const dummyState = this.state;
        let allValid = true;

        Object.keys(dummyState['Fields']).forEach( ( key ) => {
            if( dummyState['Fields'][key]['notValidated'] ) {
                allValid = false;
            }
        });

        if( allValid ) {
            const formObj = {};
            const formData = new FormData( event.target );
            const { sendFormStart } = this.props;

            formData.forEach( (val, key) => formObj[key] = val );
            const formString = JSON.stringify( formObj );

            console.log( 'All fields valid! ' + formString );
            sendFormStart( formString );
        }
        else {
            event.target.className += " show-errors";
        }
    }

    handleChange = ( event ) => {
        const dummyState = this.state;
        const { name, value } = event.target;
        dummyState['Fields'][name]['value'] = value;
        
        // Validation
        const vEmail = dummyState['Fields'][name]['vEmail'];
        if( vEmail ) {
            if( ( dummyState['Fields'][name]['value'].includes( '@' ) ) && ( dummyState['Fields'][name]['value'].includes( '.' ) ) ) {
                dummyState['Fields'][name]['notValidated'] = false;
            }
            else {
                dummyState['Fields'][name]['notValidated'] = true;
            }
        }
        else {
            if( dummyState['Fields'][name]['value'].length >= dummyState['Fields'][name]['vMinLength'] ) {
                dummyState['Fields'][name]['notValidated'] = false;
            }
            else {
                dummyState['Fields'][name]['notValidated'] = true;
            }
        }


        this.setState({ dummyState });
    }

    handleAccept = ( maskValue, maskName ) => {
        const dummyState = this.state;
        dummyState['Fields'][maskName]['value'] = maskValue;

        // Validation
        if( dummyState['Fields'][maskName]['value'].length >= dummyState['Fields'][maskName]['vMinLength'] ) {
            dummyState['Fields'][maskName]['notValidated'] = false;
        }
        else {
            dummyState['Fields'][maskName]['notValidated'] = true;
        }

        this.setState({ dummyState });
    }

    render() {
        const { Fields } = this.state;
        const { Field1, Field2, Field3, Field4, Field5 } = Fields;
        const { formStatus } = this.props;

        return(
            <div className={ 'page-content page-contact ' + formStatus } >
                <h1>You've reached JD's Messaging Service</h1>
                <p>Please leave a message with your name, phone number, email, and I'll get back to you as soon as I can.</p>

                <hr />

            { ( formStatus === 'sending' ) ?
                <div className="form-message form-loader">
                    <h2 className="header">Sending...</h2>
                    <i className="icon far fa-envelope"></i>
                </div>
            :
                null
            }

            { ( formStatus === 'success' ) ?
                <div className="form-message form-success">
                    <h2 className="header">Success!</h2>
                    <div className="text">Message received. Please expect a response within 48 hours.</div>
                </div>
            :
                null
            }

            { ( formStatus === 'failure' ) ?
                <div className="form-message form-failure">
                    <h2 className="header">Something Went Wrong!</h2>
                    <div className="text">There was an error trying to send your message.  Please refresh and try again, I want to hear from you!</div>
                    <div className="btn-refreshForm">Refresh Form</div>
                </div>
            :
                null
            }

            { ( formStatus === 'loaded' ) ?
                <form onSubmit={ this.handleSubmit } className="form form-contact" >
                    <div className="form-group form-half">
                        { ( Field1['notValidated'] ) ? <div className="error-message">Please enter your First name</div> : '' }

                        <label className="form-label">
                            <span className={ "label-text" + ( Field1['value'].length ? " shrink" : "" ) } >First Name</span>
                            <input name="Field1" className="form-field" value={ Field1['value'] } type="text" onChange={ this.handleChange } />
                        </label>
                    </div>
                    <div className="form-group form-half">
                        { ( Field2['notValidated'] ) ? <div className="error-message">Please enter your Last name</div> : '' }

                        <label className="form-label">
                            <span className={ "label-text" + ( Field2['value'].length ? " shrink" : "" ) } >Last Name</span>
                            <input name="Field2" className="form-field" value={ Field2['value'] } type="text" onChange={ this.handleChange } />
                        </label>
                    </div>

                    <div className="form-group form-half">
                        { ( Field3['notValidated'] ) ? <div className="error-message">Please enter your Email</div> : '' }

                        <label className="form-label">
                            <span className={ "label-text" + ( Field3['value'].length ? " shrink" : "" ) } >Email</span>
                            <input name="Field3" className="form-field" value={ Field3['value'] } type="email" onChange={ this.handleChange } />
                        </label>
                    </div>

                    <div className="form-group form-half">
                        { ( Field4['notValidated'] ) ? <div className="error-message">Please enter a valid Phone</div> : '' }

                        <label className="form-label">
                            <span className={ "label-text" + ( Field4['value'].length ? " shrink" : "" ) } >Phone</span>
                            <IMaskInput
                                mask={'+{1}(000) 000-0000'}
                                value={ Field4['value'] }
                                unmask={true} // true|false|'typed'
                                inputRef={el => this.input = el}  // access to nested input
                                // DO NOT USE onChange TO HANDLE CHANGES!
                                // USE onAccept INSTEAD
                                onAccept={
                                    // depending on prop above first argument is
                                    // `unmaskedValue` if `unmask=true`,
                                    // `value` if `unmask=false`,
                                    // `typedValue` if `unmask='typed'`
                                    // (value) => this.handleChange(  )
                                    (value, masked) => this.handleAccept( value, masked.el.input.name )
                                }
                                // input props also available
                                className="form-field"
                                name="Field4"
                                type="tel"
                            />
                        </label>
                    </div>

                    <div className="form-group form-textarea">
                        { ( Field5['notValidated'] ) ? <div className="error-message">Please write a message</div> : '' }

                        <label className="form-label">
                            <span className={ "label-text" + ( Field5['value'].length ? " shrink" : "" ) } >Message</span>
                            <textarea name="Field5" className="form-field" value={ Field5['value'] } rows="3" onChange={ this.handleChange }></textarea>
                        </label>
                    </div>

                    <div className="form-group form-submit">
                        <input name="saveForm" className="btn-primary btn-submit" type="submit" value="Send Message" />
                    </div>
                </form>
            :
                null
            }
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    formStatus: selectFormStatus,
});

const mapDispatchToProps = dispatch => ({
    sendFormStart: ( fieldString ) => dispatch( sendFormStart( fieldString ) )
});

export default connect( mapStateToProps, mapDispatchToProps )( Contact );