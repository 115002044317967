import React from 'react';

import { activateVisualizerDesign } from '../../redux/visualizer/visualizer.actions';
import { connect } from 'react-redux';
import { setCurrentActiveShutter } from '../../redux/shutters/shutters.actions';

const About = ( { setCurrentActiveShutter, activateVisualizerDesign } ) => {
    return(
        <div className="page-content page-about">
            <h1>Hi there!</h1>
            <h2>My name is JD.</h2>

            <p>I'm pretty good at coding websites.</p>

            <div className="btn-primary" onClick={ () => activateVisualizerDesign( 'jd', 'resume' ) } >View Resume</div>

            <hr/>
            <h3>A brief history</h3>
            <p>After graduating college with a degree in Computer Science, I started working at an entry level position as a Junior Web Developer.  Kept on learning, and soon was a full WordPress Developer.  Learned even more, and made my way up to Senior Developer.  Because I'm quite an active thinker, they threw on an additional title of Innovation Specialist.  In my current role, I've helped manage our team alongside the Department Head, and bring some new tech to the company!</p>

            <hr/>
            <h3>Some skills in WoW (World of WordPress):</h3>
            <ul className="ul-custom">
                <li>Expert at coding fully customized WordPress themes (no theme builders)</li>
                <li>I know how to bait a hook (WordPress hooks)</li>
                <li>Expert at building frontend tools for clients using Advanced Custom Fields (ACF)</li>
                <li>Sassy expert at styling using SCSS</li>
                <li>Master Jedi using JavaScript and jQuery to bring life to websites</li>
                <li>Meticulous expert at ensuring proper W3 Validation</li>
                <li>Ambitious coder trying to create sites that are as Web Accessible as possible, for all users to be able to navigate through</li>
                <li>Page Speed... I'm as good as Google's Page Speed Insights will allow.  But I promise, my sites are fast and render resources quickly</li>
            </ul>

            <hr/>
            <h3>Some skills with React:</h3>
            <ul className="ul-custom">
                <li>I predominantly prefer using Redux and Sagas to manage data, coupled with React Router when needed.</li>
                <li>The rest is traditional ES6, functional components, SCSS styling, and a whole lot of CSS animations that prevent browser rendering chaos.</li>
                <li>I generally tend to lean away from Context API, Class components, and lifecycle methods, as I've found most state management can be well... managed using Redux and Sagas.</li>
                <li>I'm 50/50 with React Hooks.  I've definitely found more use for them in certain scenarios.</li>
                <li>React is changing on a daily basis.  My mentality is to use tried and tested foundational principles, and explore new tech where applicable.</li>
                <li>I love Single Page Applications!  That's what you're seeing right now on my site.  My current passion is finding real world practical needs that I can apply my React skills on.</li>
            </ul>

            <hr/>
            <h3>Some miscellaneous skills:</h3>
            <ul className="ul-custom">
                <li>I'm adaptable.  Whatever environment I'm placed in, I not only learn quickly, but am able to optimize and improve whatever new processes or standards are currently in place.</li>
                <li>Great Instructor (I started at the bottom of the Development Chain, so I know what it's like to be a Junior Developer trying to learn the ropes)</li>
                <li>I'm a fast, but accurate typer (reduces coding times)</li>
                <li>I use 4-space indentation (but I'm willing to change to 2-space for the right company)</li>
                <li>I never forget a semi-colon (trust me, I've learned the hard way)</li>
            </ul>

            <hr/>
            <h3>Some known weaknesses:</h3>
            <ul className="ul-custom">
                <li>I'm not a natural expert at Recursive Functions (but c'mon, who is!)</li>
                <li>I'm a bit of a clean freak with code (I never leave unfinished, broken, or outdated code in my files)</li>
                <li>If you have a puppy, I will pet it</li>
            </ul>
        
            <hr/>
            <h3>Didn't see what you were looking for?</h3>
            <p>No problem! Shoot me a message using the button below and let's chat about it.</p>
            <div className="btn-primary" onClick={ () => setCurrentActiveShutter('right') }>Contact form</div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    setCurrentActiveShutter: clickedShutter => dispatch( setCurrentActiveShutter( clickedShutter ) ),
    activateVisualizerDesign: ( key, size ) => dispatch( activateVisualizerDesign( { key, size } ) )
})

export default connect( null, mapDispatchToProps )( About );