import React from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectActiveShutter } from '../../redux/shutters/shutters.selectors';
import { setCurrentActiveShutter } from '../../redux/shutters/shutters.actions';

const Shutters = ( { currentActiveShutter, setCurrentActiveShutter } ) => {
    const shuttersContainerClass = ( currentActiveShutter ) ? ' open open-' + currentActiveShutter : ' closed';

    return(
        <div className={ "shutters-container" + shuttersContainerClass }>
            <div className="shutter shutter-left" >
                <div className="shutter-label h1 hover-underline turn-left" onClick={ () => setCurrentActiveShutter('left') } >About</div>
            </div>
            <div className="shutter shutter-center" >
                <div className="shutter-label h1 hover-underline" onClick={ () => setCurrentActiveShutter('center') } >Portfolio</div>
            </div>
            <div className="shutter shutter-right" >
                <div className="shutter-label h1 hover-underline turn-right" onClick={ () => setCurrentActiveShutter('right') } >Contact</div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    currentActiveShutter: selectActiveShutter,
});

const mapDispatchToProps = dispatch => ({
    setCurrentActiveShutter: clickedShutter => dispatch( setCurrentActiveShutter( clickedShutter ) )
});

export default connect( mapStateToProps, mapDispatchToProps )( Shutters );