import { ClientList } from "./_DATA-CLIENTS";

const INITIAL_STATE = {
    clientList: ClientList
};

const clientsReducer = ( state=INITIAL_STATE, action ) => {
    switch( action.type ) {
        default:
            return state;
    }
}

export default clientsReducer;