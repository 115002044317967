import React from 'react';
import './App.scss';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectVisualizerDesignActive, selectVisualizerCodeActive } from './redux/visualizer/visualizer.selectors';

import BodyMain from './components/body-main/body-main.component';
import HeaderMain from './components/header-main/header-main.component';
import Shutters from './components/shutters/shutters.component';
import VisualizerCode from './components/visualizer/visualizer-code.component';
import VisualizerDesign from './components/visualizer/visualizer-design.component';

const App = ( { visualizerDesignActive, visualizerCodeActive } ) => {
    return (
        <div className="App">
            <HeaderMain />
            <Shutters />
            <BodyMain />

            { visualizerDesignActive ? <VisualizerDesign /> : null }
            { visualizerCodeActive ? <VisualizerCode /> : null }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    visualizerDesignActive: selectVisualizerDesignActive,
    visualizerCodeActive: selectVisualizerCodeActive,
});

export default connect( mapStateToProps )( App );