import React from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectActiveShutter } from '../../redux/shutters/shutters.selectors';

import About from '../../pages/about/about.component';
import Contact from '../../pages/contact/contact.component';
import Portfolio from '../../pages/portfolio/portfolio.component';

const BodyMain = ( { currentActiveShutter } ) => {
    const bodyMainClass = ( currentActiveShutter ) ? ' show-page show-page-' + currentActiveShutter : ' hidden';
    let bodyPage = null;

    switch( currentActiveShutter ) {
        case 'left':
            bodyPage = <About />;
        break;

        case 'center':
            bodyPage = <Portfolio />;
        break;

        case 'right':
            bodyPage = <Contact />;
        break;

        default:
            bodyPage = '';
    }

    return(
        <div className={ "body-main scrollbar-custom" + bodyMainClass }>
            { bodyPage }
        </div>

    )
}

const mapStateToProps = createStructuredSelector({
    currentActiveShutter: selectActiveShutter,
});

export default connect( mapStateToProps )( BodyMain );