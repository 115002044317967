import React from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectClientList } from '../../redux/clients/clients.selectors';
import { selectVisualizerClientKey } from '../../redux/visualizer/visualizer.selectors';
import { selectVisualizerCodeLanguage } from '../../redux/visualizer/visualizer.selectors';
import { deactivateVisualizerCode, setCodeLanguage } from '../../redux/visualizer/visualizer.actions';

const VisualizerCode = ( { clientList, visualizerClientKey, visualizerCodeLanguage, setCodeLanguage, deactivateVisualizerCode } ) => {
    return(
        <div className="visualizer visualizer-code scrollbar-custom-light">
            <div className="visualizer-wrapper">
                <div className="visualizer-close-button" onClick={ deactivateVisualizerCode } >Close Code Visualizer <i className="far fa-times-circle"></i></div>

                <div className="btn-toggles">
                    <div className={ 'btn-toggle btn-toggle-php' + ( ( visualizerCodeLanguage === 'php' ) ? ' active' : '' ) } onClick= { () => setCodeLanguage('php') } >PHP</div>
                    <div className={ 'btn-toggle btn-toggle-js' + ( ( visualizerCodeLanguage === 'js' ) ? ' active' : '' ) } onClick= { () => setCodeLanguage('js') } >JS</div>
                    <div className={ 'btn-toggle btn-toggle-scss' + ( ( visualizerCodeLanguage === 'scss' ) ? ' active' : '' ) } onClick= { () => setCodeLanguage('scss') } >SCSS</div>
                </div>

                <div className={ "visualizer-element scrollbar-custom show-block-" + visualizerCodeLanguage }>
                   <div className={ "data-container data-" + visualizerClientKey }>
                        <div className="data-meta">
                            <div className="h3">{ clientList[visualizerClientKey]['featuredCode'] }</div>
                        </div>
                        <div className="data-code-blocks">
                            <div className="code-block block-php">This is PHP code</div>
                            <div className="code-block block-js">This is JS code</div>
                            <div className="code-block block-scss">This is SCSS code</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    clientList: selectClientList,
    visualizerClientKey: selectVisualizerClientKey,
    visualizerCodeLanguage: selectVisualizerCodeLanguage,
});

const mapDispatchToProps = dispatch => ({
    setCodeLanguage: lang => dispatch( setCodeLanguage( lang ) ),
    deactivateVisualizerCode: () => dispatch( deactivateVisualizerCode() ),
});

export default connect( mapStateToProps, mapDispatchToProps )( VisualizerCode );