import { FormActionTypes } from "./forms.types";

export const setFormSending = () => ({
    type: FormActionTypes.SET_FORM_SENDING,
})

export const sendFormStart = ( formString ) => ({
    type: FormActionTypes.SEND_FORM_START,
    payload: formString
})

export const sendFormFailure = error => ({
    type: FormActionTypes.SEND_FORM_FAILURE,
    payload: error
})

export const sendFormSuccess = () => ({
    type: FormActionTypes.SEND_FORM_SUCCESS,
})