import { createSelector } from "reselect";

// Input
const selectVisualizer = state => state.visualizer;

// Output
// Visualizer - All
export const selectVisualizerClientKey = createSelector(
    [selectVisualizer],
    selectVisualizer => selectVisualizer.visualizerClientKey
);

// Visualizer - Design
export const selectVisualizerDesignActive = createSelector(
    [selectVisualizer],
    selectVisualizer => selectVisualizer.visualizerDesignActive
);

export const selectVisualizerDesignSize = createSelector(
    [selectVisualizer],
    selectVisualizer => selectVisualizer.visualizerDesignSize
);

// Visualizer - Design
export const selectVisualizerCodeActive = createSelector(
    [selectVisualizer],
    selectVisualizer => selectVisualizer.visualizerCodeActive
);

export const selectVisualizerCodeLanguage = createSelector(
    [selectVisualizer],
    selectVisualizer => selectVisualizer.visualizerCodeLanguage
);