import React from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectClientList } from '../../redux/clients/clients.selectors';
import { selectVisualizerClientKey } from '../../redux/visualizer/visualizer.selectors';
import { selectVisualizerDesignSize } from '../../redux/visualizer/visualizer.selectors';
import { setDesignSize, deactivateVisualizerDesign } from '../../redux/visualizer/visualizer.actions';

import resumeImgSrc from '../../assets/imgs/Resume-JD-Larrea.jpg';

const VisualizerDesign = ( { clientList, visualizerClientKey, visualizerDesignSize, setDesignSize, deactivateVisualizerDesign } ) => {
    // Local Variables
    const isDesktop = ( visualizerDesignSize === 'desktop' ) ? true : false;
    const isTablet = ( visualizerDesignSize === 'tablet' ) ? true : false;
    const isMobile = ( visualizerDesignSize === 'mobile' ) ? true : false;
    const isResume = ( visualizerDesignSize === 'resume' ) ? true : false;
    let clientObj = null;
    let imgSrcDesktop = null;
    let imgSrcTablet = null;
    let imgSrcMobile = null;
    let imgAlt = "JD's Resume";

    if( !isResume ) {
        clientObj = clientList[visualizerClientKey];
        imgSrcDesktop = ( clientObj['mockImages']['desktop'] ) ? clientObj['mockImages']['desktop'] : '';
        imgSrcTablet = ( clientObj['mockImages']['tablet'] ) ? clientObj['mockImages']['tablet'] : '';
        imgSrcMobile = ( clientObj['mockImages']['mobile'] ) ? clientObj['mockImages']['mobile'] : '';
        imgAlt = visualizerDesignSize + " design mock for " + clientObj['clientName'];
    }
    
    // Local Functions
    const handleToggleClick = size => {
        switch(size) {
            case 'desktop':
                if( imgSrcDesktop ) { setDesignSize( size ); }
            return;
            case 'tablet':
                if( imgSrcTablet ) { setDesignSize( size ); }
            return;
            case 'mobile':
                if( imgSrcMobile ) { setDesignSize( size ); }
            return;
            default:
                return;
        }
    }

    return(
        <div className="visualizer visualizer-design scrollbar-custom-light">
            <div className="visualizer-wrapper">
                <div className="visualizer-close-button" onClick={ deactivateVisualizerDesign } >Close Design Visualizer <i className="far fa-times-circle"></i></div>

                { !(isResume) ?
                    <div className="btn-toggles">
                        <div className={ 'btn-toggle btn-toggle-desktop' + ( isDesktop ? ' active' : '' ) + ( imgSrcDesktop ? '' : ' disabled' ) } onClick= { () => handleToggleClick('desktop') } >Desktop</div>
                        <div className={ 'btn-toggle btn-toggle-tablet' + ( isTablet ? ' active' : '' ) + ( imgSrcTablet ? '' : ' disabled' ) } onClick= { () => handleToggleClick('tablet') } >Tablet</div>
                        <div className={ 'btn-toggle btn-toggle-mobile' + ( isMobile ? ' active' : '' ) + ( imgSrcMobile ? '' : ' disabled' ) } onClick= { () => handleToggleClick('mobile') } >Mobile</div>
                    </div>
                : null }

                <div className={ "visualizer-element scrollbar-custom " + visualizerDesignSize }>
                    { isDesktop ?
                        <img src={ imgSrcDesktop } alt={ imgAlt } className="visualizer-img" />
                    : null }
                    { isTablet ?
                        <img src={ imgSrcTablet } alt={ imgAlt } className="visualizer-img" />
                    : null }
                    { isMobile ?
                        <img src={ imgSrcMobile } alt={ imgAlt } className="visualizer-img" />
                    : null }
                    { isResume ?
                        <img src={ resumeImgSrc } alt={ imgAlt } className="visualizer-img" />
                    : null }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    clientList: selectClientList,
    visualizerClientKey: selectVisualizerClientKey,
    visualizerDesignSize: selectVisualizerDesignSize,
});

const mapDispatchToProps = dispatch => ({
    setDesignSize: size => dispatch( setDesignSize( size ) ),
    deactivateVisualizerDesign: () => dispatch( deactivateVisualizerDesign() ),
});

export default connect( mapStateToProps, mapDispatchToProps )( VisualizerDesign );