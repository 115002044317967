import { takeLatest, put, all, call, delay } from "redux-saga/effects";

import { FormActionTypes } from "./forms.types";
import { sendFormFailure, sendFormSuccess, setFormSending } from "./forms.actions";

export function* setLoading() {
    yield put( setFormSending() );
}

export function* sendFormToWebhook( { payload } ) {
    yield call( setLoading );
    yield delay(3000);

    try {
        const response = yield call( fetch, '//jdlarrea.com/webhook-jdlarrea.php', {
            method: 'POST',
            body: payload
        } );
        const dataString = yield call( [ response, response.json] );
        const dataObj = JSON.parse( dataString );

        if( dataObj.Success === 1 ) {
            yield put( sendFormSuccess() );
        }
        else {
            const customError = 'Something went wrong communicating with the webhook.'
            yield put( sendFormFailure( customError ) );
        }
    }
    catch(error) {
        yield put( sendFormFailure( error ) );
    }
}

export function* onSendFormStart() {
    yield takeLatest( FormActionTypes.SEND_FORM_START, sendFormToWebhook )
}

export function* formSagas() {
    yield all([
        call( onSendFormStart ),
    ]);
}